import React from "react"
import { Timeline, message, Button } from "antd"
import { navigate } from "gatsby"

import { getCoinRecordList } from "../api/coinRecord"
import { getUserSelf } from "../api/user"

import Layout from "../layouts"

import "./coins.css"

class Coins extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      currentPage: 2,
      hasMore: true,
    }
  }

  componentWillMount() {
    // 避免构建失败
    if (typeof window === "undefined" || typeof document === 'undefined') {
      return;
    }
    this.initCoinRecordList()
    this.initUserInfo()
  }

  initUserInfo = async () => {
    const res = await getUserSelf()
    if (res && res.data.success) {
      const { coinAmount, coinTmpAmount } = res.data.data
      this.setState({
        coinSumAmount: coinAmount + coinTmpAmount,
      })
    }
  }

  initCoinRecordList = async () => {
    const res = await getCoinRecordList(1, 10)
    const data = res.data.data
    if (data) {
      this.setState({ data: data.coinRecordList })
      this.setState({ hasMore: data.coinRecordList.length !== data.pageNum })
    } else {
      message.error("加载墨点列表出错")
    }
  }

  handleMore = async () => {
    const res = await getCoinRecordList(this.state.currentPage, 10)
    if (res.data && res.data.data) {
      const list = this.state.data.concat(res.data.data.coinRecordList)
      this.setState({
        data: list,
        currentPage: this.state.currentPage + 1,
        hasMore: list.length !== res.data.data.pageNum,
      })
    } else {
      message.error("加载墨点列表出错")
    }
  }

  handleCoinRecharge = () => {
    navigate("/membership/sell-coin")
  }

  render() {
    const { data, hasMore } = this.state
    return (
      <Layout isSpacerDisplay={true} title="我的墨点">
        <div className="mdnice-orders-container">
          <div className="mdnice-orders-inner-container">
            <Button onClick={this.handleCoinRecharge}>墨点充值</Button>
            <p style={{ marginTop: "30px", color: "rgb(48,132,214)" }}>
              墨点余额：{this.state.coinSumAmount}
            </p>
            <div className="mdnice-orders-table-container">
              <Timeline mode="left">
                {data.map(item => (
                  <Timeline.Item
                    key={item.id}
                    color={item.changeType ? "green" : "red"}
                    label={item.createTime}
                  >
                    <p style={{ fontWeight: "bold" }}>{item.name}</p>
                    <p>
                      {item.changeType ? "增加" : "减少"}
                      {item.changeNum} 墨点
                    </p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
            {hasMore && <Button onClick={this.handleMore}>加载更多</Button>}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Coins
