import { axiosBackEnd, getCookie } from "../utils/helper"
import { TOKEN } from "../utils/constant"
import { message } from "antd"

export const getCoinRecordList = async (currentPage, pageSize) => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  try {
    return await axiosBackEnd.get(`/coinRecords/user?currentPage=${currentPage}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch(e) {
    console.log(e);
  }
}
